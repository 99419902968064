export default {
    data () {
        return {
            leftActiveBarStyle: 'transform: translateY(0px)'
        }
    },
    props: {
        secondLevelMenuList: {
            type: Array,
            default: () => []
        }
    },
    components: {},
    created () {
    },
    mounted () {
        this.handleActive()
    },
    activated () {
    },
    deactivated() {},
    destroyed () {
    },
    watch: {
        secondLevelMenuList() {
            this.handleActive()
        },
    },
    computed: {},
    filters: {},
    methods: {
        mouseOverLeftPopup(val) {
            let item = this.$refs[`leftMenuSpan${val}`]
            let width = item[0].offsetWidth
            let left = item[0].offsetLeft
            let popup = this.$refs[`leftMenuPopup${val}`]
            if (!popup) {
                return
            }
            if (popup.length === 0) {
                return;
            }
            popup[0].style.left = `${width + left + 8}px`
        },
        handleMouseOver(val) {
            let item = this.$refs[`menuItem${val}`][0]
            let top = item.offsetTop
            this.leftActiveBarStyle = `transform: translateY(${top}px)`
        },
        handleActive(val) {
            if (this.secondLevelMenuList.length === 0) {
                return
            }
            let valNum = 0
            if (val) {
                valNum = val
            } else {
                for (let i = 0, l = this.secondLevelMenuList.length; i < l; i++) {
                    if (this.secondLevelMenuList[i].active) {
                        valNum = i
                        break
                    }
                }
            }
            this.$nextTick(() => {
                let item = this.$refs[`menuItem${valNum}`][0]
                let top = item.offsetTop
                this.leftActiveBarStyle = `transform: translateY(${top}px)`
            })
        },
        jumpNext(val) {
            console.log(val)
            let routeName = ''
            let getRouteName = function (list) {
                if (list[0].routeName) {
                    routeName = list[0].routeName
                } else {
                    getRouteName(list[0].children)
                }
            }
            if (val.routeName) {
                routeName = val.routeName
            } else {
                getRouteName(val.children)
                // routeName = val.children[0].children[0].routeName
            }
            this.$router.push({
                name: routeName
            })
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
