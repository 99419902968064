import Q from "q";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import consumer_user_logout from "yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_user_logout";
import consumer_workflow from '@/lib/data-service/haolv-default/consumer_workflow';
import refund_change_policy_get from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get';
import wangyiqiyu from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index.js'
import DownlodApp from "@/component/download-app/index.vue";
import {awaitWrap} from "@/page/admin/company-management/staff-management/common/unit";
import * as dd from "dingtalk-jsapi";

export default {
  data() {
    return {
      userInfo: {},
      policy: {},
    };
  },
  props: {
    navList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DownlodApp,
  },
  created() {},
  async mounted() {
    console.log('121212222333232323232');
    this.$store.commit('setUserInfo', {});
    get_user_info().then((res) => {
      this.userInfo = res.datas;
      this.$store.commit('setUserInfo', JSON.parse(JSON.stringify(this.userInfo)));
      this.$store.state.userInfoDefer.resolve();
      console.log('保存userinfo');
    });
    this.$store.commit('setPolicy', {});
    refund_change_policy_get().then(res=>{
      this.policy = res.datas;
      this.$store.commit('setPolicy', JSON.parse(JSON.stringify(this.policy)));
      this.$store.state.policyDefer.resolve();
      console.log('保存policy');
    })
    this.$store.commit('setWorkflow', '2');
    let [err, res] = await awaitWrap(this.getWorkflow());
    /*if (err) {
      return;
    }*/
    let workflow = res.datas ? '1' : '2';
    this.$store.commit('setWorkflow', workflow);
    this.$store.state.workflowDefer.resolve();

  },
  activated() {},
  deactivated() {},
  destroyed() {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
    async getWorkflow() {
      let [err, res] = await awaitWrap(consumer_workflow());
      if (err) {
        return {
          datas: false,
        };
      }
      return res;
    },
    to_home () {
        if (this.$route.name !== 'admin-travel-book-home') {
            this.$router.push({name: 'admin-travel-book-home'});
        }
    },

    to_personal_center () {
      this.$router.push({
        name: 'admin-my-information'
      })
    },


    jumpNext(val) {
      let routeName = "";
      if (val.routeName) {
        routeName = val.routeName;
      } else if (val.children[0].routeName) {
        routeName = val.children[0].routeName;
      } else {
        routeName = val.children[0].children[0].routeName;
      }
      this.$router.push({
        name: routeName,
      });
    },



    __btn_logout_click() {
      const __this = this;
      if (dd.env.platform !== "notInDingTalk") {
        dd.biz.navigation.quit;
        return;
      }
      Q.when()
        .then(function() {
          return consumer_user_logout();
        })
        .then(function() {
          wangyiqiyu.hide()
          if (dd.env.platform !== "notInDingTalk") {
            dd.getStorage({
              key: 'corpId',
              success: (res) => {
                __this.$router.replace({
                  name: `dd-login`,
                  query: {
                    corpId: res,
                  }
                });
              },
              fail: () => {},
              complete: () => {},
            });
            return;
          }
          __this.$router.replace({
            name: `login`,
          });
        })
        .catch(function(ex) {
          wangyiqiyu.hide()
          if (dd.env.platform !== "notInDingTalk") {
            dd.getStorage({
              key: 'corpId',
              success: (res) => {
                __this.$router.replace({
                  name: `dd-login`,
                  query: {
                    corpId: res,
                  }
                });
              },
              fail: () => {},
              complete: () => {},
            });
            return;
          }
          __this.$router.replace({
            name: `login`,
          });
          console.error(ex);
          console.trace();
          if (ex) {
            if (ex.stack) {
              console.error(ex.stack);
            }
          }
          return Q.reject(ex);
        });
    },
  },
};
