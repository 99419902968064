export default {
    data() {
        return {}
    },
    props: {
        thirdLevelMenuList: {
            type: Array,
            default: () => []
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        jumpNext(val) {
            let routeName = ''
            if (val.routeName) {
                routeName = val.routeName
            } else {
                routeName = val.children[0].routeName
            }
            this.$router.push({
                name: routeName
            })
        }
    }
}