import consumer_journey_myJourneyProve from '@/lib/data-service/haolv-default/consumer_journey_myJourneyProve';
import consumer_journey_myJourneyProvePdf from '@/lib/data-service/haolv-default/consumer_journey_myJourneyProvePdf'
import {awaitWrap} from "@/page/admin/company-management/staff-management/common/unit";
import baseUrl from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix";
import moment from "moment";
export default {
    data() {
        return {
            tabsActiveBarStyle: 'width: 0px; transform: translateX(0px)',
            hideTab: false,
            dialogPrintReportVisible: false, // 显示打印水印弹窗
            printLoading: false,
            printForm: {
                startDate: '',
                endDate: '',
                dataArr: [],
                businessType: '',
            },
            printPickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e6
                },
            },
            options: [
                {value: 1, label: '机票'},
                {value: 3, label: '酒店'},
                {value: 2, label: '火车票'},
                {value: 4, label: '用车'},
            ],
        }
    },
    props: {
        thirdLevelMenuList: {
            type: Array,
            default: () => []
        }
    },
    components: {},
    created() {
    },
    mounted() {
        this.getMetaHideTab()
        this.handleActive()
    },
    activated() {
    },
    deactivated() { },
    destroyed() {
    },
    watch: {
        thirdLevelMenuList() {
            this.handleActive()
        },
        $route(newVal, oldVal) {
            console.log('router', newVal, oldVal);
            this.getMetaHideTab()
        }
    },
    computed: {},
    filters: {},
    methods: {
        getMetaHideTab() {
            this.hideTab = this.$route.meta.hideTab
        },
        handleMouseOver(val) {
            let item = this.$refs[`menuItem${val}`][0]
            let width = item.offsetWidth
            let left = item.offsetLeft
            this.tabsActiveBarStyle = `width: ${width}px; transform: translateX(${left}px)`
        },
        handleActive(val) {
            if (this.thirdLevelMenuList.length === 0) {
                return
            }
            let valNum = 0
            if (val) {
                valNum = val
            } else {
                for (let i = 0, l = this.thirdLevelMenuList.length; i < l; i++) {
                    if (this.thirdLevelMenuList[i].active) {
                        valNum = i
                        break
                    }
                }
            }
            this.$nextTick(() => {
                let item = this.$refs[`menuItem${valNum}`][0]
                let width = item.offsetWidth
                let left = item.offsetLeft
                this.tabsActiveBarStyle = `width: ${width}px; transform: translateX(${left}px)`
            })
        },
        jumpNext(val) {
            let routeName = ''
            if (val.routeName) {
                routeName = val.routeName
            } else {
                routeName = val.children[0].routeName
            }
            this.$router.push({
                name: routeName
            })
        },

        printJourneyReport() {
            this.dialogPrintReportVisible = true;
        },
        setPrintTime(val) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * val);
            const startDay = moment(start).format('yyyy-MM-DD')
            const endDay = moment(end).format('yyyy-MM-DD')
            console.log(startDay);
            this.printForm.dataArr = [startDay, endDay];
            this.printForm.startDate = startDay;
            this.printForm.endDate = endDay;
        },
        changePrint(val) {
            if (val) {
                this.printForm.startDate = val[0];
                this.printForm.endDate = val[1];
            } else {
                this.printForm.startDate = '';
                this.printForm.endDate = '';
            }
        },
        async _submitPrint() {
            if (!this.printForm.dataArr || this.printForm.dataArr.length === 0) {
                this.$message({
                    type: "warning",
                    message: '选择行程时间'
                })
                return;
            }
            this.printLoading = true;
            let [err, res] = await awaitWrap(consumer_journey_myJourneyProve(this.printForm));
            if (err) {
                this.printLoading = false;
                return
            }
            let exportTime = res.datas.exportTime;
            let userName = res.datas.userName;
            let [pErr, pRes] = await awaitWrap(consumer_journey_myJourneyProvePdf({exportTime, userName}));
            if (pErr) {
                this.printLoading = false;
                return
            }
            let html = pRes.datas.html;
            const environment = global.appConfig.environment;
            const urlPrefix = baseUrl({
                environment: environment,
                appKey: "haolv-default"
            });
            console.log(urlPrefix, html);
            console.log('1111');
            this.printLoading = false;
            window.open(urlPrefix + '/consumer/journey/print/pdf/' + html);
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
