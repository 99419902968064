export default {
    data() {
        return {}
    },
    props: {
        secondLevelMenuList: {
            type: Array,
            default: () => []
        }
    },
    components: {},
    created() {
    },
    mounted() {
        this.handleActive()
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        secondLevelMenuList() {
            this.handleActive()
        },
    },
    computed: {},
    filters: {},
    methods: {
        handleActive(val) {
            if (this.secondLevelMenuList.length === 0) {
                return
            }
            let valNum = 0
            if (val) {
                valNum = val
            } else {
                for (let i = 0, l = this.secondLevelMenuList.length; i < l; i++) {
                    if (this.secondLevelMenuList[i].active) {
                        valNum = i
                        break
                    }
                }
            }
        },
        jumpNext(val) {
            console.log(val)
            let routeName = ''
            let getRouteName = function (list) {
                if (list[0].routeName) {
                    routeName = list[0].routeName
                } else {
                    getRouteName(list[0].children)
                }
            }
            if (val.routeName) {
                routeName = val.routeName
            } else {
                getRouteName(val.children)
                // routeName = val.children[0].children[0].routeName
            }
            this.$router.push({
                name: routeName
            })
        }
    }
}