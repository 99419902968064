// consumer_journey_myJourneyProvePdf
const __request = require(`./__request/__request_contentType_json`);
const consumer_journey_myJourneyProvePdf = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/journey/myJourneyProvePdf',
    data: pParameter,
    // enable_error_alert: false // 临时隐藏
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_journey_myJourneyProvePdf;
