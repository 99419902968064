export default {
    data () {
        return {
            hideTab: false,

        }
    },
    props: {
        fourthLevelMenuList: {
            type: Array,
            default: () => []
        }
    },
    components: {},
    created () {
    },
    mounted () {
        this.getMetaHideTab()
    },
    activated () {
    },
    deactivated() {},
    destroyed () {
    },
    watch: {
        $route() {
            this.getMetaHideTab()
        }
    },
    computed: {},
    filters: {},
    methods: {
        getMetaHideTab() {
            this.hideTab = this.$route.meta.hideTab
        },
        handleActive(val) {
            if (this.fourthLevelMenuList.length === 0) {
                return
            }
            let valNum = 0
            if (val) {
                valNum = val
            } else {
                for (let i = 0, l = this.fourthLevelMenuList.length; i < l; i++) {
                    if (this.fourthLevelMenuList[i].active) {
                        valNum = i
                        break
                    }
                }
            }
        },
        jumpNext(val) {
            this.$router.push({
                name: val.routeName
            })
        },

    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {
  },
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
